<template>
  <JobsLayout class="jobs-home">
    <template #heading>
      <div class="jobs-home-header">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h1 v-html="$t('JOBS.HOME.@MAIN_TITLE')" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h2 class="text-body2 text-bold" v-html="$t('JOBS.HOME.@MAIN_SUBTITLE')" />
      </div>
    </template>
    <template #storesBanner>
      <section class="jobs-stores-app-banner full-width bg-secondary text-center text-white column justify-center">
        <QImg
          v-if="!isSmallScreen"
          :src="require('@core/assets/img/extracadabra_app.png')"
          :draggable="false"
          width="140px"
          class="jobs-stores-app-img"
        />
        <div class="jobs-stores-app-text q-my-lg font-115">
          {{ $filters.whitespacedPunctuation($t("JOBS.HOME.@EASY_APPLY_IN_NEW_APP")) }}
        </div>
        <div class="jobs-stores-app-links bg-white">
          <StoresComponent class="full-width text-center" candidate full-size smart-store />
        </div>
      </section>
    </template>
    <template #content>
      <main class="jobs-home-main">
        <JobsSearchForm dense @submit="submitSearch" />
        <article>
          <DynamicPagesBlockComponent style="margin-bottom: 55px" :title="$t('JOBS.HOME.@POPULAR_SEARCHES')" :dynamic-pages="popularSearches" />
          <DynamicPagesBlockComponent :title="$t('JOBS.HOME.@HIRING_SECTORS')" :dynamic-pages="dynamicPages" />
          <DynamicPagesBlockRegion :title="$t('JOBS.HOME.@REGION_OFFER')" :dynamic-pages="searchByLocalization" />
        </article>

        <JobsCreationStatus />
        <section>
          <span class="text-h2">
            {{ $t("JOBS.HOME.@RECENT_OFFERS") }}
          </span>
          <JobOffersList :job-offers-list="jobsSearchList.getJobsOffersList()" />
        </section>
        <section class="q-my-md text-center">
          <QBtn type="a" :to="searchRoute" color="secondary" :label="$t('JOBS.LABELS.@SEE_MORE')" />
        </section>
      </main>
    </template>
  </JobsLayout>
</template>

<script>
  import { createMetaMixin } from "quasar";
  import { JOBS_ROUTES } from "@jobs/config";
  import { MetaHelpers } from "@core/helpers";
  import {
    ACTION_JOBS_GET_METADATA_BY_ROUTENAME,
    ACTION_JOBS_GET_POPULAR_SEARCHES,
    ACTION_JOBS_GET_LOCALIZATION_SEARCHES,
    ACTION_JOBS_GET_DYNAMICS_PAGES,
    ACTION_JOBS_GET_JOB_OFFERS,
    GETTER_JOBS_GET_CURRENT_METADATA,
    GETTER_JOBS_QUALIFICATIONS_GET_ALL,
    GETTER_JOBS_GET_POPULAR_SEARCHES,
    GETTER_JOBS_GET_LOCALIZATION_SEARCHES,
    GETTER_JOBS_GET_DYNAMICS_PAGES,
    GETTER_JOBS_GET_JOB_OFFERS,
  } from "@jobs/store/types";

  import JobsLayout from "@jobs/layouts/Jobs.layout.vue";
  import DynamicPagesBlockRegion from "@jobs/components/dynamic/DynamicPagesBlockRegion.component.vue";
  import JobsSearchForm from "@jobs/components/search/JobsSearch.form.vue";
  import JobOffersList from "@jobs/components/offers/JobOffersList.component.vue";
  import StoresComponent from "@core/components/media/Stores.component.vue";
  import JobsCreationStatus from "@jobs/components/marketing/JobsCreationStatus.component.vue";
  import DynamicPagesBlockComponent from "@jobs/components/dynamic/DynamicPagesBlock.component.vue";

  export default {
    name: "JobsHome",
    components: {
      JobsSearchForm,
      JobsLayout,
      DynamicPagesBlockComponent,
      JobOffersList,
      StoresComponent,
      JobsCreationStatus,
      DynamicPagesBlockRegion,
    },
    preFetch({ store }) {
      return Promise.all([
        store.dispatch(ACTION_JOBS_GET_METADATA_BY_ROUTENAME, { routeName: JOBS_ROUTES.public.home.name }),
        store.dispatch(ACTION_JOBS_GET_POPULAR_SEARCHES),
        store.dispatch(ACTION_JOBS_GET_LOCALIZATION_SEARCHES),
        store.dispatch(ACTION_JOBS_GET_DYNAMICS_PAGES),
        store.dispatch(ACTION_JOBS_GET_JOB_OFFERS),
      ]);
    },
    mixins: [
      createMetaMixin(function () {
        return MetaHelpers.setAutoMeta({
          title: this.seo.getTitle(),
          description: this.seo.getDescription(),
          type: this.seo.getType(),
          image: this.seo.getImg(),
          canonical: this.seo.getCanonical(),
        });
      }),
    ],
    data() {
      return {
        closeBanner: false,
      };
    },
    computed: {
      isSmallScreen() {
        return this.$q.screen.lt.md;
      },
      seo() {
        return this.$store.getters[GETTER_JOBS_GET_CURRENT_METADATA];
      },
      qualifications() {
        return this.$store.getters[GETTER_JOBS_QUALIFICATIONS_GET_ALL];
      },
      popularSearches() {
        return this.$store.getters[GETTER_JOBS_GET_POPULAR_SEARCHES];
      },
      searchByLocalization() {
        return this.$store.getters[GETTER_JOBS_GET_LOCALIZATION_SEARCHES];
      },
      dynamicPages() {
        return this.$store.getters[GETTER_JOBS_GET_DYNAMICS_PAGES];
      },
      jobsSearchList() {
        return this.$store.getters[GETTER_JOBS_GET_JOB_OFFERS];
      },
    },
    created() {
      this.searchRoute = { name: JOBS_ROUTES.public.search.name };
    },
    methods: {
      submitSearch({ selectedQualifications = [], selectedContracts = [], selectedPostalCodes = [] } = {}) {
        const currentQuery = this.$route.query;
        const query = {
          ...currentQuery,
          q: selectedQualifications,
          c: selectedContracts,
          cp: selectedPostalCodes,
        };

        this.$router.push({ name: JOBS_ROUTES.public.search.name, query }).catch((suppressedError) => {});
      },
    },
  };
</script>

<style lang="scss">
  .jobs-home {
    // we style the layout div
    .extracadabra-landing-background {
      background-image: url("/img/home_background.jpg");
    }
    .jobs-stores-app-banner {
      .jobs-stores-app-text {
        max-width: 500px;
        width: 40vw;
        margin: 16px auto;
        @media screen and (max-width: $breakpoint-sm-max) {
          width: 80vw;
        }
      }
      .jobs-stores-app-links {
        border-radius: 40px 40px 0 0;
        figure {
          margin: 0;
        }
        img {
          width: 200px !important;
        }
      }
      .jobs-stores-app-img {
        position: absolute;
        left: 0;
        margin-left: 16%;
        margin-bottom: 110px;
      }
    }

    .jobs-home-header {
      padding-left: 8%;
      padding-right: 8%;
      width: 100%;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      h1 {
        margin-top: 0;
        max-width: 500px;
      }
      .text-body2 {
        max-width: 600px;
        a {
          color: #fff;
        }
      }
      @media screen and (max-width: $breakpoint-sm) {
        padding: 0;
        h1 {
          margin-top: 0;
        }
      }
    }
  }
</style>
