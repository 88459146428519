<template>
  <QItem class="jobs-ae-selling-points-component">
    <figure v-for="(sellingPoint, index) in sellingPoints" :key="index" class="jobs-ae-selling-points-label q-ma-none q-gutter-sm">
      <img :src="sellingPoint.iconSrc" :alt="$t(sellingPoint.iconAlt)" />
      <figcaption class="text-center">
        {{ $t(sellingPoint.description) }}
      </figcaption>
    </figure>
  </QItem>
</template>

<script>
const missionImg = require("@core/assets/img/target.svg");
const priceImg = require("@core/assets/img/balance_white.svg");
const paymentImg = require("@core/assets/img/bank_white.svg");
const insuranceImg = require("@core/assets/img/shield_white.svg");

export default {
  created() {
    this.sellingPoints = [
      {
        iconSrc: missionImg,
        iconAlt: "JOBS.AE_BANNER.@MISSION_ALT",
        description: "JOBS.AE_BANNER.@MISSION_DESCRIPTION",
      },
      {
        iconSrc: priceImg,
        iconAlt: "JOBS.AE_BANNER.@REMUNERATION_ALT",
        description: "JOBS.AE_BANNER.@REMUNERATION_DESCRIPTION",
      },
      {
        iconSrc: paymentImg,
        iconAlt: "JOBS.AE_BANNER.@PAYMENT_ALT",
        description: "JOBS.AE_BANNER.@PAYMENT_DESCRIPTION",
      },
      {
        iconSrc: insuranceImg,
        iconAlt: "JOBS.AE_BANNER.@INSURANCE_DESCRIPTION",
        description: "JOBS.AE_BANNER.@INSURANCE_ALT",
      },
    ];
  },
};
</script>

<style lang="scss">
.jobs-ae-selling-points-component {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  > .jobs-ae-selling-points-label {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    width: 25%;
    min-height: 140px;
    min-width: 150px;
    @media screen and (max-width: $breakpoint-sm) {
      align-items: center;
    }
    > p {
      height: 50px;
      padding-top: 15px;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      @media screen and (max-width: $breakpoint-sm) {
        text-align: center;
      }
    }
    > img {
      width: 75px;
      height: 75px;
      align-self: center;
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    > .jobs-ae-informations-label {
      flex-direction: row;
      padding: 5px;
      > img {
        width: 50px;
      }
    }
  }
}
</style>
