<template>
  <main class="jobs-dynamic-region-component">
    <h2>{{ title }}</h2>
    <article class="jobs-dynamic-block-content">
      <section v-for="page in dynamicPages" :key="page.getId()" class="dynamic-block-link">
        <QCard v-if="page.getImg()" style="height: 150px">
          <img class="dynamic-image" :src="page.getImg()" :alt="page.getImgTitle()" />
          <div class="absolute-right dynamic-link" style="padding: 0">
            <h3>
              <QBtn class="full-width" align="right" :to="getPageLink(page.getId())" :label="page.getLabel()" icon-right="icon-f-arrow-right" />
            </h3>
            <div class="text-center q-mb-md">
              <p>
                {{ $tc("JOBS.HOME.@REGION_OFFER_NUMBER", { count: page.getTotalOffers() }) }}
              </p>
              <QIcon class="text-center q-mb-md" size="xl" :name="`icon-extra-${page.getId()}`" style="font-size: 70px" />
            </div>
          </div>
        </QCard>
        <QBtn v-else color="accent" type="a" padding="50px" :to="getPageLink(page.getId())" :label="page.getLabel()" />
      </section>
    </article>
  </main>
</template>

<script>
  import { JOBS_ROUTES } from "@jobs/config";

  export default {
    name: "DynamicPagesBlock",
    props: {
      dynamicPages: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: "",
      },
    },
    methods: {
      getPageLink(slug) {
        const query = this.$route.query;
        const encodedSlug = encodeURIComponent(slug);
        const routeData = this.$router.resolve({ name: JOBS_ROUTES.public.dynamic.name, query, params: { slug: encodedSlug } });
        return routeData.href;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .jobs-dynamic-region-component {
    .jobs-dynamic-block-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .dynamic-block-link {
        width: 100%;
        max-width: 370px;
        margin: 15px;
      }
      .dynamic-image {
        height: 100%;
        object-fit: cover;
      }
      .dynamic-link {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.47);
      }
      .card-btn {
        width: 180px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
</style>
