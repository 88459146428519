<template>
  <QCard class="text-white q-ma-lg jobs-ae-information-card">
    <QCardSection class="jobs-ae-information-title text-bold">
      <h2 class="text-h4 q-pt-lg text-bold text-center jobs-ae-information-title-description">
        {{ $t("CORE.COMPONENTS.MARKETING.@TITLE") }}
      </h2>
    </QCardSection>
    <QCardSection>
      <JobsAeSellingsPoints />
    </QCardSection>
    <QCardSection class="text-center">
      <QBtn
        class="padding-0-20 text-bold q-mb-md"
        color="secondary"
        no-caps
        :label="$t('CORE.COMPONENTS.MARKETING.@LABEL')"
        @click="openTypeformPopup"
      />
    </QCardSection>
  </QCard>
</template>

<script>
import JobsAeSellingsPoints from "@jobs/components/marketing/JobsAeSellingsPoints.component.vue";
import { EventManager } from "@core/managers/event.manager";
import { JOBS_EVENTS } from "@jobs/config";
import { TYPEFORM_AE_STEPS_URL } from "@core/constants";

export default {
  components: {
    JobsAeSellingsPoints,
  },
  methods: {
    openTypeformPopup() {
      EventManager.emit(JOBS_EVENTS.OPEN_TYPEFORM, TYPEFORM_AE_STEPS_URL);
    },
  },
};
</script>

<style lang="scss" scoped>
.jobs-ae-information-card {
  background-color: $primary;
}
</style>
